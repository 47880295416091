/* RSI Variables */
:root {
  --rsi-primary: #47b5be;
  --rsi-primary-light: #93ebe3;
  --rsi-primary-dark: #237088;

  --rsi-yellow: #f5b400;
  --rsi-blue: #003a66;
  --rsi-red: #be4747;

  --rsi-gray-1: #495058;
  --rsi-gray-2: #edf6f7;
  --rsi-gray-3: #c3d8dd;
  --rsi-gray-4: #f2f2f2;
  --rsi-gray-5: #fbf8f3;
}

/* RSI Override Theme Prime */
:root {
  --primary-color: var(--rsi-primary);
  --highlight-text-color: var(--rsi-primary-dark);

  --text-color: var(--rsi-gray-1);
  --text-color-secondary: var(--rsi-blue);
}

/* RSI Background */
.rsi-bg-primary {
  background-color: var(--rsi-primary) !important;
}

.rsi-bg-gray-1 {
  background-color: var(--rsi-gray-1) !important;
}

.rsi-bg-gray-2 {
  background-color: var(--rsi-gray-2) !important;
}

.rsi-bg-gray-3 {
  background-color: var(--rsi-gray-3) !important;
}

.rsi-bg-gray-4 {
  background-color: var(--rsi-gray-4) !important;
}

.rsi-bg-gray-5 {
  background-color: var(--rsi-gray-5) !important;
}

.rsi-bg-blue {
  background-color: var(--rsi-blue) !important;
}

.rsi-bg-yellow {
  background-color: var(--rsi-yellow) !important;
}

.rsi-bg-red {
  background-color: var(--rsi-red) !important;
}

.rsi-bg-primary-mix {
  background-color: color-mix(in srgb, var(--rsi-primary) 20%, white);
}

.rsi-bg-gray-mix {
  background-color: color-mix(in srgb, var(--rsi-gray-1) 20%, white);
}

.rsi-bg-blue-mix {
  background-color: color-mix(in srgb, var(--rsi-blue) 20%, white);
}

.rsi-bg-yellow-mix {
  background-color: color-mix(in srgb, var(--rsi-yellow) 20%, white);
}

.rsi-bg-red-mix {
  background-color: color-mix(in srgb, var(--rsi-red) 20%, white);
}

/* RSI Overlay */
.rsi-overlay {
  background-color: rgba(0, 0, 0, 0.4);
}

/* RSI Shadow */
.rsi-shadow {
  transform: translateX(-50%);
  filter: drop-shadow(0px 0px 5px rgba(0, 0, 0, 0.5));
}

/* RSI Container */
.rsi-container-web {
  max-width: 1200px;
  margin: auto;
}

.rsi-container-app {
  max-width: 1600px;
  margin: auto;
}

/* Cover */
.rsi-cover {
  background-repeat: no-repeat;
  background-position: bottom right;

  @media screen and (max-width: 1024px) {
    background-image: none !important;
  }
}

.rsi-word-break-all {
  word-break: break-all;
}

/* Prime Menu */
.p-menu {
  .p-menuitem-link:focus {
    box-shadow: none;
  }
}

/* Prime Button */
.p-button {
  background-color: var(--primary-color);
  border-color: var(--primary-color);

  &:enabled:active,
  &:enabled:hover,
  &:enabled:focus,
  &:focus {
    background-color: var(--rsi-primary-dark);
    border-color: var(--rsi-primary-dark);
    color: var(--primary-color-text);
    box-shadow: none !important;
  }

  &.p-button-outlined {
    border-color: var(--primary-color);
    background-color: transparent;
    color: var(--primary-color);

    &.p-button-outlined:enabled:active,
    &.p-button-outlined:enabled:hover,
    &.p-button-outlined:enabled:focus,
    &.p-button-outlined:focus {
      border-color: var(--rsi-primary-dark);
      color: var(--rsi-primary-dark);
      background-color: transparent;
    }
  }
}

/* Input */
.p-inputtext {
  &:enabled:hover {
    border-color: var(--primary-color);
  }

  &:enabled:focus {
    border-color: var(--rsi-primary-dark);
    box-shadow: none;
  }
}

/* Progress Bar */
.p-progressbar .p-progressbar-value {
  background-color: var(--rsi-primary);
}

/* Prime Dialog */
.p-dialog .p-dialog-header {
  background-color: var(--primary-color);
  border-radius: 0;
  color: white;
}

.p-dialog .p-dialog-footer {
  border-radius: 0;
}

/* Prime Dropdown */
.p-dropdown {
  &:not(.p-disabled):hover {
    border-color: var(--primary-color);
  }

  &:not(.p-disabled):focus,
  &:not(.p-disabled).p-focus {
    border-color: var(--rsi-primary-dark);
    box-shadow: none;
  }

  .p-dropdown-item {
    white-space: inherit;
  }

  .p-dropdown-item.p-highlight {
    background-color: var(--rsi-primary-light);
    color: var(--rsi-primary-dark);
  }

  .p-dropdown-item-group {
    cursor: default !important;
    height: auto !important;
  }
}

/* Prime Radio */
.p-radiobutton {
  .p-radiobutton-box {
    border-color: var(--rsi-gray-3);

    &.p-highlight {
      background-color: var(--rsi-primary);
      border-color: var(--rsi-primary);
    }
  }

  &:not(.p-radiobutton-disabled) {
    .p-radiobutton-box.p-highlight:focus,
    .p-radiobutton-box.p-highlight.p-focus {
      border-color: var(--rsi-primary-dark);
      box-shadow: none;
    }

    .p-radiobutton-box.p-highlight:hover {
      background-color: var(--rsi-primary-dark);
      border-color: var(--rsi-primary-dark);
    }
  }
}

/* Prime Checkbox */
.p-checkbox {
  .p-checkbox-box {
    border-color: var(--rsi-gray-3);

    &.p-highlight {
      background-color: var(--rsi-primary);
      border-color: var(--rsi-primary);
    }
  }

  &:not(.p-checkbox-disabled) {
    .p-checkbox-box.p-highlight:focus,
    .p-checkbox-box.p-highlight.p-focus {
      border-color: var(--rsi-primary-dark);
      box-shadow: none;
    }

    .p-checkbox-box.p-highlight:hover {
      background-color: var(--rsi-primary-dark);
      border-color: var(--rsi-primary-dark);
    }
  }
}

/* Prime FileUpload */
.p-fileupload {
  @extend .flex, .flex-column, .flex-column-reverse, .justify-content-end, .align-items-stretch;

  .p-fileupload-choose:not(.p-disabled):hover {
    background-color: var(--rsi-primary-dark);
    border-color: var(--rsi-primary-dark);
  }

  & > * {
    @extend .align-self-start;
  }

  .p-message-wrapper {
    @extend .p-2;
  }

  .p-message-icon,
  .p-message-summary {
    display: none;
  }
}

/* Prime TabMenu */
.p-tabmenu {
  & .p-tabmenu-nav-content {
    overflow: visible;
  }

  & .p-tabmenu-nav {
    background: transparent;
  }

  & .p-tabmenu-nav {
    border: none;

    .p-tabmenuitem {
      &:hover .p-menuitem-link {
        background: var(--rsi-primary-dark) !important;
        color: var(--primary-color-text) !important;
      }

      &.p-highlight {
        position: relative;

        .p-menuitem-link {
          background: var(--rsi-primary-dark);
          color: var(--primary-color-text);
        }

        &::after {
          -webkit-clip-path: polygon(50% 100%, 0 0, 100% 0);
          clip-path: polygon(50% 100%, 0 0, 100% 0);
          background: var(--rsi-primary-dark);
          left: calc(50% - 10px);
          position: absolute;
          height: 10px;
          width: 20px;
          content: '';
          top: 100%;
        }
      }

      .p-menuitem-link {
        color: var(--primary-color-text);
        background: transparent;
        border-radius: 0;
        font-weight: 500;
        border: none;
        margin: 0;

        &:hover,
        &:focus {
          background: var(--rsi-primary-dark);
          color: var(--primary-color-text);
          box-shadow: none !important;
        }
      }
    }
  }
}

/* Prime Steps */
.p-steps {
  @extend .py-3;

  li[aria-selected='true'].p-steps-item {
    opacity: 1;
  }

  .p-steps-item .p-menuitem-link {
    background: transparent;

    &:focus {
      box-shadow: none !important;
    }

    .p-steps-number {
      border: none;
      font-size: 0;
      height: 80px;
      width: 80px;
    }

    .p-steps-title {
      @extend .text-lg, .text-color-secondary, .font-bold, .text-center, .white-space-normal;
    }
  }

  @for $i from 1 through 6 {
    .p-steps-item:nth-child(#{$i}) {
      .p-steps-number {
        background-image: url('/assets/images/stepper-' + $i + '.svg');
      }

      &.p-steps-current {
        .p-steps-number {
          background-image: url('/assets/images/stepper-' + $i + '-active.svg');
        }
      }
    }
  }
}

/* Prime Confirm Dialog */
.p-confirm-dialog .p-dialog-content {
  @extend .py-5, .justify-content-center, .text-center;

  .p-confirm-dialog-message {
    @extend .text-lg, .text-color-secondary, .font-bold, .m-0;
  }
}

/* Prime DataTable */
.p-datatable {
  &.p-datatable-no-overflow {
    .p-datatable-wrapper {
      overflow: visible !important;
    }
  }

  .p-datatable-thead {
    & > tr {
      & > th {
        @extend .py-2, .text-sm, .text-white, .font-normal, .text-center, .rsi-bg-blue;

        &:not(:last-child) {
          @extend .border-right-1;
        }
      }

      &.rsi-table-header2 > th {
        @extend .text-sm, .text-color-secondary, .font-bold, .text-center, .rsi-bg-gray-2;
      }

      &.rsi-table-header3 > th {
        @extend .text-sm, .text-white, .font-normal, .text-center, .rsi-bg-gray-1;
      }
    }

    .p-sortable-column {
      .p-sortable-column-icon {
        color: var(--primary-color-text) !important;
      }

      &.p-highlight,
      &:hover {
        background-color: var(--rsi-gray-1) !important;
        box-shadow: none;
      }
    }
  }

  .p-datatable-scrollable-table > .p-datatable-tbody > .p-rowgroup-header,
  .p-datatable-scrollable-table > .p-datatable-thead {
    @extend .z-auto;
  }

  .p-datatable-tbody {
    & > tr {
      &.disabled > td {
        pointer-events: none;
        opacity: 0.5;

        &.envelope {
          color: var(--red-500);
        }
      }

      & > td {
        @extend .text-center;
      }
    }
  }
}

/* Prime Datepicker */
.p-datepicker table {
  @extend .text-xs;

  td {
    padding: 0.1rem;
  }
}

/* Prime Accordion */
.p-accordion {
  .p-accordion-header .p-accordion-header-link {
    background-color: var(--rsi-primary) !important;
    color: var(--primary-color-text) !important;

    &.p-accordion-header-error {
      background-color: var(--rsi-red) !important;
    }
  }
}

/* Prime Overlay */
.p-overlaypanel {
  background-color: var(--rsi-gray-4);
  box-shadow: none;
  color: inherit;

  &::after {
    border: none;
  }
}

/* Prime Timeline */
.p-timeline {
  .p-timeline-event-opposite {
    padding-left: 0 !important;
  }

  .p-timeline-event-content {
    padding-right: 0 !important;
  }
}

/* Prime Tooltip */
.p-tooltip {
  width: auto;
  max-width: none;
}
