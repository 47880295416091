@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;700&display=swap');
@import "primeng/resources/themes/lara-light-teal/theme.css";
@import "primeng/resources/primeng.min.css";
@import "primeflex/primeflex.scss";
@import "primeicons/primeicons.css";
@import "theme.scss";

html {
    scroll-behavior: smooth;
}

body {
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    margin: 0;
}
